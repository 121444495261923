export const clearBodyLockScroll = (): void => {
  const BODY = document.querySelector('body');
  if (BODY) {
    BODY.style.overflow = '';
    BODY.style.position = '';
    BODY.style.padding = '';
    BODY.style.paddingRight = '';
    BODY.style.margin = '';
    BODY.style.pointerEvents = '';
    BODY.style.touchAction = '';
  }
};

export const enableBodyLockScroll = (): void => {
  const BODY = document.querySelector('body');
  if (BODY) {
    BODY.style.overflow = 'hidden';
    BODY.style.position = 'relative';
    BODY.style.padding = '0';
    BODY.style.paddingRight = 'var(--scrollbar)';
    BODY.style.margin = '0';
    BODY.style.pointerEvents = 'none';
    BODY.style.touchAction = 'none';
  }
};
